import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Capricorn.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Capricorn Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/capricorn"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Capricorn </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Capricorn Woman</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Capricorn Woman</h1>
                <h2 className="text-sm md:text-base ml-4">Dec 22 - Jan 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-love");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-nature");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-man");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-traits");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-facts");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">

            Capricorn women are intelligent but too idealistic to believe in the world's ideas or follow the path to success. Their natural talent for innovation is manifested in their strong will and the ability to push themselves to perform at their best. They are the ideological but not the idealists. They can be very stubborn and never change, even when told to do so.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Capricorn Women in Nature</p><br/>
Capricorns are women who embody the ideal image of the female who is active, assertive, aggressive, confident, and has strong self-discipline. They are a powerful group of women who live a life of making the most out of their existing energies.
 <br/><br/>
These are likely to be polled to determine how they will vote. But this could not be more true for the Capricorn women. They are likely to perform the duties of the Capricorn feminists in a determined manner. For example, a Capricorn woman is likely to be a leader taking care of her home and family, and a Capricorn man is likely to be a leader taking care of his home and family.<br/><br/>
Capricorns are the most successful women globally, as they can solve challenging problems, achieve great things, and have a deep passion for their work, being in their fields of expertise.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Intelligent</p><br/>
Capricorns and Capricornine women are thought to be the most intense, gifted and steadfast, and the most responsible and aggressive of the women born under the sign of the Goat. They are incredibly resourceful, playful, and self-reliant and are known to excel at a wide range of tasks, both practical and intellectual. They are also powerful.
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">Goal-oriented </p><br/>
Capricorn women have a strong will, are very active, and are the most likely to achieve great things, and they are also the most likely to be successful. The Capricorn women's self-image is also possibly the most influential on the success and development of their careers.<br/><br/>
Capricorns value their own time, and when they are not occupied, they are busy.<br/><br/>
These women are powerful and determined, and they will fight for their dreams.
 <br/><br/>
If you want to understand more about Capricorn women, read more from our website pages. Also, try the Birth Chart based personalized report; choose our best astrologer for your exact future prediction regarding health, finance, career, relationship, and what you asked for. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Perfectionist </p><br/>
Capricorn women are strong, independent, and self-confident. They are experts in many fields. They are confident in their abilities and are often very clever and original thinkers.
Capricorn women have an Amazonian desire for a unified society. They want to make their society one that is both strong and beautiful. Capricorn women talk about the need for a beautiful and robust community, so they are more likely to talk about the moon's power. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Enthusiastic</p><br/>
Capricorn women will continue to strive for a beautiful and robust society because they believe this is the only authentic way to achieve the changes they desire genuinely.<br/><br/>
The women of Capricorn can muster up the strength and power to achieve their goals, and they are always looking to acquire new ways to enhance their skills and performance. They can make things happen through their initiative, and they are not afraid to push themselves to the edge of their comfort zone.
 <br/><br/>
The Capricorn women are willing to change their lives and take risks. They are good at making changes and taking risks and are eager to experiment with new things.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Balance</p><br/>
The women of this Sign crave security, power, and progress and will not shy away from experimenting with new things. Capricorn women are both conservative and outgoing. Capricorn women can be very outgoing but also very traditional. Their ability to adapt is sometimes called their 'Capricorn-ness,' and it is acknowledged when they are 'outgoing' when it comes to business and relationships. But unlike many other women, they do not take themselves too seriously. Moreover, these optimistic women have a dynamic way of dealing with challenges. They succeed in managing both domestic and professional work efficiently.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Capricorn women in relatio</p><br/>
Capricorns are happy with they are in relationship and bound to make it. They take pride in their loyalty. They can step out of a bad situation and carry on a good one. They have the physical strength and agility to handle any situation.
 <br/><br/>
These women are fair and have a strong sense of justice. They give and demand respect. Women believe that everyone should get equal opportunities to build a secure future. The Birth Chart based personalized birth chart is a specialized tool that looks at your career to determine your future. First, we look at your overall career development, including successes and failures. Second, we look at your professional achievements, including your best and worst career growth.
Women often feel that they need to prove themselves to others and that the only way to do that is to do something easy. The reason is that women are often afraid of failure. To prove yourself to others, you need to do something easy. You need to prove that you can do it and that you won't fail.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Logical</p><br/>
Capricorn women are also known as rational women or logical women. They are very logical and have a very analytical mind. They will never make impulsive decisions just for the sake of excitement. They are also known as rational women or logical women.
 <br/><br/>
They often use their logic and exploration to determine the best path to take before making a decision, and they will never make impulsive choices just for the sake of excitement.<br/><br/>
Women are a very individualistic group; they value their beliefs but give more weight to the positive aspects of a situation than its negative aspects. They are perfectionists and always strive for perfect performances, which they view as a good person's mark.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Sense of humor</p><br/>
Capricorn women bring up issues and problems, are more aware of the negative aspects, and are not afraid to go against the norm. They will not compromise their values or principles. The Capricorn women are cautious about their ideas and opinions, and they often have a sense of humor. Capricorn women believe in having fun and having a sense of humor.
 <br/><br/>
Many women interested in becoming professional women do not want to defy their parents or the society of their parents. Many of them feel that kids are a waste of time. They will not let the kids fall into the hands of anyone but their parents.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Honest</p><br/>
One of the significant factors contributing to a person's attraction to someone is how they relate to others. It is particularly true with the people they socialize with. Friends play a significant role in their lives. However, many people they associate with do not provide the same level of support as they would like.<br/><br/>
Friends can make or break a relationship, and the ones who are easily influenced by others can be the ones that should be the most careful around them.
 <br/><br/>
While the Capricorn women are not romantic, they are not in the least. Their motto is "Always be honest."
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Acceptance</p><br/>
They appreciate honest criticism. Women like to cut corners and compromise on quality and standards in what they do. On the other hand, they do it that they feel they are superior to their male counterparts.
 <br/><br/>
Those who want to cut corners or compromise on quality may have difficulty adjusting to the nature of these meticulous and determined women.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">On the negative side:</p>
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Pessimist</p><br/>
Capricorn women are prone to pessimism and depression if they suffer setbacks. And if they suffer such setbacks regularly, the depression it causes can be very severe. This is a possible consequence of the depressive tendencies that Capricorn women have. The Capricorn woman's tendency to be pessimistic and depressed can be easily triggered when these women feel that they have no way out. Capricorn women should use their emotional strength positively. For any solution, talk to our astrologers. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Afraid of rejection</p><br/>
A Capricorn woman is not a woman who will go through the same ups and downs and not be afraid of rejection. She will not allow her partner to dictate terms, but she will support them through ups and downs.
 <br/><br/>
When the Capricorn women are in a romantic relationship, they do not let their partners dictate terms, though they will support them through ups and downs.<br/><br/>
It means they will respect their partners and will not fight with them. However, they will also be compassionate and understanding and refrain from saying things that may cause them to lose friends or even their partners.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">What they want from their partner</p><br/>
As Capricorn women, we are prone to be reactive to men and other women. We are easily attracted to others, but we don't like to be controlled by them. Our strength lies in our ability to be independent and objective for the rest of our lives. However, when it comes to love, our behavior is very different.<br/><br/>
The man should understand what the woman wants, and the woman should do the same. They should listen to each other, support each other and respect each other's opinions. It is essential to balance the man and the woman constantly.
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
